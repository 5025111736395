<template>
  <div class="users-table">
    <v-container grid-list-xl fluid>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>{{
              $vuetify.lang.t('$vuetify.user.users')
            }}</v-card-title>
            <v-toolbar flat color="white">
              <v-text-field
                text
                solo
                flat
                clearable
                prepend-icon="search"
                :placeholder="$vuetify.lang.t('$vuetify.toolbar.search')"
                v-model="search"
                hide-details
                class="hidden-sm-and-down"
              ></v-text-field>
              <v-btn small rounded @click="init" class="mr-2">
                <v-icon>mdi-refresh</v-icon>
                {{ $vuetify.lang.t('$vuetify.buttons.refresh') }}
              </v-btn>
              <v-btn small rounded color="success" @click="dialog = !dialog">
                <v-icon>mdi-plus</v-icon>
                {{ $vuetify.lang.t('$vuetify.buttons.create') }}
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="compHeaders"
                :items="items"
                fixed-header
                class="elevation-1"
                item-key="id"
                :loading="tableLoading"
                :options.sync="options"
                :server-items-length="totalItems"
              >
                <template v-slot:item.avatar="{ item }">
                  <v-avatar class="ma-1">
                    <img
                      alt="Avatar"
                      :src="item.avatar ? item.avatar : 'static/img/av.png'"
                    />
                  </v-avatar>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="editItem(item)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.buttons.edit') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="deleteItem(item)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                      $vuetify.lang.t('$vuetify.buttons.delete')
                    }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="deletePhoto(item)"
                      >
                        <v-icon>mdi-camera-off</v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                      $vuetify.lang.t('$vuetify.different.deletePhoto')
                    }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="showPermissions(item)"
                      >
                        <v-icon>mdi-account-cancel</v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                      $vuetify.lang.t('$vuetify.different.permissions')
                    }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog persistent v-model="dialog" max-width="1100px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="itemForm" lazy-validation v-model="valid">
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    prepend-icon="person"
                    autocomplete="off"
                    name="name"
                    :label="$vuetify.lang.t('$vuetify.user.name') + '*'"
                    :placeholder="$vuetify.lang.t('$vuetify.user.name')"
                    type="text"
                    required
                    counter
                    :rules="[rules.required, rules.max191]"
                    v-model="item.name"
                    :error="!!errors.name"
                    :error-messages="errors.name"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    prepend-icon="person"
                    autocomplete="off"
                    name="surname"
                    :label="$vuetify.lang.t('$vuetify.user.surname') + '*'"
                    :placeholder="$vuetify.lang.t('$vuetify.user.surname')"
                    type="text"
                    required
                    counter
                    :rules="[rules.required, rules.max191]"
                    v-model="item.surname"
                    :error="!!errors.surname"
                    :error-messages="errors.surname"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    prepend-icon="alternate_email"
                    autocomplete="off"
                    name="email"
                    :label="$vuetify.lang.t('$vuetify.login.email') + '*'"
                    :placeholder="$vuetify.lang.t('$vuetify.login.email')"
                    type="email"
                    required
                    counter
                    :rules="[rules.required, rules.email, rules.max191]"
                    v-model="item.email"
                    :error="!!errors.email"
                    :error-messages="errors.email"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    prepend-icon="lock"
                    autocomplete="off"
                    name="password"
                    :label="$vuetify.lang.t('$vuetify.login.password')"
                    :placeholder="$vuetify.lang.t('$vuetify.login.password')"
                    :type="showPass ? 'text' : 'password'"
                    :rules="[rules.min8, rules.max191]"
                    required
                    counter
                    v-model="item.password"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPass = !showPass"
                    :error="!!errors.password"
                    :error-messages="errors.password"
                  />
                </v-col>
                <v-col cols="12" md="8">
                  <v-file-input
                    show-size
                    :rules="[rules.maxSize10Mb]"
                    accept="image/png, image/jpeg, image/bmp"
                    :label="$vuetify.lang.t('$vuetify.different.photo')"
                    v-model="file"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*{{ $vuetify.lang.t('$vuetify.different.indicate') }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
            :disabled="itemLoading"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.close') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
            :disabled="!valid"
            :loading="itemLoading"
            >{{ compAction }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">{{
          $vuetify.lang.t('$vuetify.different.deleteItemConfirm')
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDelete = false"
            :disabled="itemLoading"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteItemConfirm"
            :loading="itemLoading"
            >{{ $vuetify.lang.t('$vuetify.buttons.delete') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogPermissions"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialogPermissions = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{
            $vuetify.lang.t('$vuetify.different.permissions')
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-data-table
          :headers="compPermissionsHeaders"
          :items="permissions"
          fixed-header
          class="elevation-1"
          item-key="id"
          :loading="permissionsLoading"
        >
          <template v-slot:item.action="{ item }">
            <v-checkbox
              v-model="item.can"
              color="primary"
              @click="togglePermission(item)"
            ></v-checkbox>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="3000"
      bottom
      right
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="snackbar.show = false" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      dialogPermissions: false,
      valid: true,
      itemLoading: false,
      tableLoading: false,
      permissionsLoading: false,
      search: null,
      awaitingSearch: false,
      showPass: false,
      file: null,
      item: {
        id: null,
        name: null,
        surname: null,
        email: null,
        password: null,
        avatar: null,
        created_at: null,
        updated_at: null,
        deleted_at: null
      },
      emptyItem: {
        id: null,
        name: null,
        surname: null,
        email: null,
        password: null,
        avatar: null,
        created_at: null,
        updated_at: null,
        deleted_at: null
      },
      rules: {
        required: (v) =>
          !!v || this.$vuetify.lang.t('$vuetify.validation.required'),
        min8: (v) =>
          !v ||
          (v && v.length >= 8) ||
          this.$vuetify.lang.t('$vuetify.validation.min8'),
        max191: (v) =>
          !v ||
          (v && v.length <= 191) ||
          this.$vuetify.lang.t('$vuetify.validation.max191'),
        email: (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
            v
          ) || this.$vuetify.lang.t('$vuetify.validation.email'),
        maxSize10Mb: (v) =>
          !v ||
          v.size < 10000000 ||
          this.$vuetify.lang.t('$vuetify.validation.maxSize10Mb')
      },
      totalItems: 0,
      options: {},
      permissionsHeaders: [
        { text: 'ID', value: 'id' },
        {
          text: this.$vuetify.lang.t('$vuetify.different.permission'),
          value: 'name'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.different.description'),
          value: 'description'
        },
        { text: '', value: 'action', sortable: false }
      ],
      items: [],
      permissions: [],
      snackbar: {
        show: false,
        text: '',
        color: ''
      },
      errors: {
        name: null,
        surname: null,
        email: null,
        password: null
      },
      defaultErrors: {
        name: null,
        surname: null,
        email: null,
        password: null
      }
    }
  },
  metaInfo() {
    return {
      title: this.$vuetify.lang.t('$vuetify.user.users')
    }
  },
  watch: {
    dialog: function(val) {
      if (!val) {
        this.item = Object.assign({}, this.emptyItem)
        this.$refs.itemForm.resetValidation()
        this.dialog = null
        this.file = null
        this.errors = Object.assign({}, this.defaultErrors)
      }
    },
    dialogDelete: function(val) {
      if (!val) {
        this.item = Object.assign({}, this.emptyItem)
      }
    },
    dialogPermissions: function(val) {
      if (!val) {
        this.item = Object.assign({}, this.emptyItem)
      }
    },
    search(val) {
      if (!val || (val && val.length >= 1)) {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.init()
            this.awaitingSearch = false
          }, 1000)
        }
        this.awaitingSearch = true
      }
    },
    item: {
      handler() {
        this.validation()
      },
      deep: true
    },
    options: {
      handler() {
        this.init()
      },
      deep: true
    }
  },
  computed: {
    compAuthHeader() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }
    },
    formTitle() {
      return this.item.id
        ? this.$vuetify.lang.t('$vuetify.buttons.edit')
        : this.$vuetify.lang.t('$vuetify.buttons.new')
    },
    compAction() {
      return this.item.id
        ? this.$vuetify.lang.t('$vuetify.buttons.save')
        : this.$vuetify.lang.t('$vuetify.buttons.create')
    },
    compHeaders() {
      return [
        { text: 'ID', value: 'id' },
        {
          text: this.$vuetify.lang.t('$vuetify.different.photo'),
          value: 'avatar',
          sortable: false
        },
        { text: this.$vuetify.lang.t('$vuetify.user.name'), value: 'name' },
        {
          text: this.$vuetify.lang.t('$vuetify.user.surname'),
          value: 'surname'
        },
        { text: this.$vuetify.lang.t('$vuetify.login.email'), value: 'email' },
        {
          text: this.$vuetify.lang.t('$vuetify.dates.createdAt'),
          value: 'created_at'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.dates.deletedAt'),
          value: 'deleted_at'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.buttons.action'),
          value: 'action',
          sortable: false
        }
      ]
    },
    compPermissionsHeaders() {
      return [
        { text: 'ID', value: 'id' },
        {
          text: this.$vuetify.lang.t('$vuetify.different.permission'),
          value: 'name'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.different.description'),
          value: 'description'
        },
        { text: '', value: 'action', sortable: false }
      ]
    }
  },
  mounted() {},
  methods: {
    editItem(item) {
      this.item = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem(item) {
      this.item = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.itemLoading = true

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/user/destroy',
          { id: this.item.id },
          this.compAuthHeader
        )
        .then((response) => {
          this.itemLoading = false
          if (response.status === 200) {
            this.init()
            this.dialogDelete = false

            this.snackbar.show = true
            this.snackbar.color = 'green'
            this.snackbar.text = this.$vuetify.lang.t(
              '$vuetify.messages.deleted'
            )
          } else {
            this.snackbar.show = true
            this.snackbar.color = 'red'
            this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
          }
        })
        .catch((error) => {
          this.itemLoading = false
          this.snackbar.show = true
          this.snackbar.color = 'red'
          this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
        })
    },
    deletePhoto(item) {
      let headers = this.compAuthHeader
      headers.headers['Content-Type'] = 'application/json'

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/user/destroy-photo',
          { id: item.id },
          headers
        )
        .then((response) => {
          if (response.status === 200) {
            this.init()

            this.snackbar.show = true
            this.snackbar.color = 'green'
            this.snackbar.text = this.$vuetify.lang.t(
              '$vuetify.messages.deleted'
            )
          } else {
            this.snackbar.show = true
            this.snackbar.color = 'red'
            this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
          }
        })
        .catch((error) => {
          this.itemLoading = false
          this.snackbar.show = true
          this.snackbar.color = 'red'
          this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
        })
    },
    save() {
      let t = this
      this.errors = Object.assign({}, this.defaultErrors)
      if (this.$refs.itemForm.validate()) {
        let formData = new FormData()
        if (this.file) formData.append('file', this.file)

        //Item
        for (let key in this.item) {
          if (this.item[key]) formData.append(key, this.item[key])
        }

        let headers = this.compAuthHeader
        headers.headers['Content-Type'] = 'multipart/form-data'

        this.itemLoading = true

        let url = 'api/user'
        if (this.item.id) url = url + '/update'

        this.$axios
          .post(this.$store.getters.getApiUrl + url, formData, headers)
          .then((response) => {
            this.itemLoading = false
            if (response.status === 200) {
              this.init()
              this.dialog = false

              this.snackbar.show = true
              this.snackbar.color = 'green'
              this.snackbar.text = this.$vuetify.lang.t(
                '$vuetify.messages.saved'
              )
            } else if (
              response.status === 422 &&
              typeof response.data.errors !== 'undefined'
            ) {
              for (let key in response.data.errors) {
                response.data.errors[key].forEach(function(item) {
                  t.errors[key] = item
                })
              }
            } else {
              this.snackbar.show = true
              this.snackbar.color = 'red'
              this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
            }
          })
          .catch((error) => {
            this.itemLoading = false
            this.snackbar.show = true
            this.snackbar.color = 'red'
            this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
          })
      }
    },
    init() {
      this.tableLoading = true
      let data = {
        search: this.search,
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        sort_by: this.options.sortBy[0],
        descending: this.options.sortDesc[0]
      }

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/users',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          if (response.status === 200) {
            this.items = response.data.data
            this.totalItems = response.data.meta.total
          }

          this.tableLoading = false
        })
        .catch((error) => {
          this.tableLoading = false
        })
    },
    validation() {
      for (let keyItem in this.item) {
        if (this.errors[keyItem]) this.errors[keyItem] = null
      }
    },
    showPermissions(item) {
      this.item = item
      this.dialogPermissions = true
      this.permissionsLoading = true
      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/user/permissions',
          { id: item.id },
          this.compAuthHeader
        )
        .then((response) => {
          if (response.status === 200) {
            this.permissions = response.data.data
          }
          this.permissionsLoading = false
        })
        .catch((error) => {
          this.permissionsLoading = false
        })
    },
    togglePermission(item) {
      let data = {
        user_id: this.item.id,
        permission_id: item.id
      }
      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/user/permission',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          if (response.status === 200) {
            this.snackbar.show = true
            this.snackbar.color = 'green'
            this.snackbar.text = this.$vuetify.lang.t('$vuetify.messages.saved')
          } else {
            this.snackbar.show = true
            this.snackbar.color = 'red'
            this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
          }
        })
        .catch((error) => {
          this.snackbar.show = true
          this.snackbar.color = 'red'
          this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
        })
    }
  }
}
</script>
